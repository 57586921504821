<template>
  <el-dialog
    :title="`Apply ${isCreditNote ? 'Credit' : 'Debit'} Note`"
    :visible="dialogSelectCdNote"
    @open="handleCdNoteDialogOpen"
    @close="handleSelectCdNoteDialogClose"
    style="text-align: left"
    height="fit-content"
  >
    <div>
      <el-table v-loading="cdNoteTableLoading" ref="cdNoteTable" :data="cdNoteTableData" height="48vh" :cell-style="{padding: '0', height: '60px'}" @selection-change="handleSelectChange">
      <el-table-column
          key="checkbox"
          type="selection"
          min-width="55"
        />
        <el-table-column label="Invoice Number">
          <template v-slot="scope">
            <div>
              {{ scope.row.createdByInvoice.systemGeneratedInvoiceNo }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Vessel/ Terminal">
          <template v-slot="scope">
            <div>
              {{ scope.row.createdByInvoice.orderVesselName ?  scope.row.createdByInvoice.orderVesselName : scope.row.createdByInvoice.orderTerminal}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Supply Date">
          <template v-slot="scope">
            <div>
              {{ formatDate(new Date(scope.row.supplyDate)) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Note Total" show-overflow-tooltip="">
          <template v-slot="scope">
            <div>
              ${{ numberWithCommas(scope.row.noteTotal, 3) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Date Created">
          <template v-slot="scope">
            <div>
              {{ formatDate(new Date(scope.row.noteDate)) }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="el-dialog__footer">
      <el-button
        outline
        round

        @click="handleSelectCdNoteDialogClose"
      >
        Cancel
      </el-button>
      <el-button
        round

        type="primary"
        @click="handleConfirmCdNoteDialogClick"
      >
        Confirm
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { numberWithCommas } from '@/utils'
import { formatDate } from '@/utils/dateTime.js'
import { CDNOTE_NOTE_TYPE, CDNOTE_STATUS } from '@/constants.js'

export default {
  name: 'DialogSelectCdNote',
  props: {
    dialogSelectCdNote: Boolean,
    isCreditNote: Boolean,
    propsCreditNoteSelected: Array,
    propsDebitNoteSelected: Array,
    appliedNotes: Array,
    buyerSysOrganizationId: String
  },
  data () {
    return {
      cdNoteTableLoading: true,
      cdNoteTableData: [],
      creditNoteSelected: [],
      debitNoteSelected: []
    }
  },
  methods: {
    numberWithCommas,
    formatDate,
    handleSelectChange (selection) {
      this.isCreditNote ? this.creditNoteSelected = selection : this.debitNoteSelected = selection
    },
    handleConfirmCdNoteDialogClick () {
      this.$emit('handleConfirmCdNoteDialogClick', this.isCreditNote ? this.creditNoteSelected : this.debitNoteSelected)
    },
    handleSelectCdNoteDialogClose () {
      this.$emit('handleSelectCdNoteDialogClose')
    },
    handleCdNoteDialogOpen () {
      this.$request.get({
        url: `${this.$apis.cdNotes}?pageSize=99&pageNumber=0&filter=status:'${CDNOTE_STATUS.AUTHORIZED_SIGNATORY_APPROVED}' and noteType:'${this.isCreditNote ? CDNOTE_NOTE_TYPE.CREDIT_NOTE : CDNOTE_NOTE_TYPE.DEBIT_NOTE}' and buyerSysOrganizationId:'${this.buyerSysOrganizationId}'`
      }).then(res => {
        if (res?.code === 1000) {
          this.cdNoteTableData = res.data.content
          this.cdNoteTableData.push(...this.appliedNotes)
          this.$refs.cdNoteTable.clearSelection()
          this.cdNoteTableLoading = false
          if (this.isCreditNote) {
            this.propsCreditNoteSelected.forEach(cn => {
              this.$nextTick(() => {
                const creditNote = this.cdNoteTableData.find(d => d.id === cn.id)
                this.$refs.cdNoteTable.toggleRowSelection(creditNote, true)
              })
            })
          } else {
            this.propsDebitNoteSelected.forEach(dn => {
              this.$nextTick(() => {
                const debitNote = this.cdNoteTableData.find(d => d.id === dn.id)
                this.$refs.cdNoteTable.toggleRowSelection(debitNote, true)
              })
            })
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-dialog__footer {
    padding: 10px 10px 0 0;
    height: 100%;
}
</style>
